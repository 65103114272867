import React from "react";

interface ContactPageProps {
}

interface ContactPageState {
}

export class ContactPage extends React.Component<ContactPageProps, ContactPageState> {
    constructor(props: ContactPageProps) {
        super(props);
    }
    render() {
        return (
            <div>Kontakt</div>
        );
    }
}
