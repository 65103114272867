import {useParams} from "react-router-dom";
import {Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Header} from "../common/Header";


interface GdprConsentPageProps {
}

interface formDataType {
    [key: string]: FormDataEntryValue
}

export const GdprConsentPage = (props: GdprConsentPageProps): any => {
    const {consentId} = useParams()

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    useEffect(() => {
    }, [])


    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto', minHeight: "95vh"}}>
            <Header/>
            <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                <Typography variant={"h1"}>Datenschutz</Typography>
                <Typography>
                    Es wurden persönliche Daten von Ihnen erfasst. Dies ist notwendig um Sie für die Lehrgänge anzumelden und eine Verwaltung
                    zu ermöglichen.
                </Typography>
                <Typography>
                    Bitte bestätigen Sie Ihre E-Mail Adresse und das Sie mit der Verarbeitung der Daten einverstanden sind.
                </Typography>
                <form onSubmit={onSubmitHandler}>
                    <FormControlLabel
                        control={
                            <Checkbox name="tnc"
                                      id="tnc" required/>
                        }
                        label="Mit dem Absenden des Formular bestätige ich, dass ich die Datenschutzerklärung und meine Rechte der betroffenen Person zur Kenntnis genommen habe.
                         Mehr Informationen entnehmen Sie bitte unseren Datenschutzhinweisen."
                    />
                    <FormGroup>
                        <Button type="submit" variant="contained">Bestätigen</Button>
                    </FormGroup>
                </form>
            </Grid>
        </Grid>
    )
}
