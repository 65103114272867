import {LoginRedirectFetch} from "../environment/LoginRedirectFetch";

export class CourseService {
    private loginRedirectFetch: LoginRedirectFetch

    constructor() {
        this.loginRedirectFetch = new LoginRedirectFetch(window.location);
    }

    async getCoursesByYear(year: Number): Promise<string> {
         const response = await this.loginRedirectFetch.fetch("/api/courses/?year=2025", {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async getCourses(): Promise<string> {
        const response = await this.loginRedirectFetch.fetch("/api/courses/", {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async adminGetCoursesWithBookings(): Promise<any> {
        const response = await this.loginRedirectFetch.fetch("/api/courses/bookings", {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async getCourse(alias: string | undefined): Promise<string | any> {
        const response = await this.loginRedirectFetch.fetch(`/api/course-templates/view/${alias}`, {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }
}
