import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://www.kfv-roth.de/">
                Feuerwehr Landkreis Roth
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Footer() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: "3rem"
            }}
        >
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#CA2B2B',
                    color: '#fff'
                }}
            >
                <Container maxWidth="sm">
                    <Copyright/>
                    <Link color="inherit" noWrap key="impressum" variant="body2" href="https://www.kfv-roth.de/index.php/service/impressum"
                          sx={{p: 1, flexShrink: 0}}>
                        Impressum
                    </Link>
                    <Link color="inherit" noWrap key="datenschutz" variant="body2" href="https://www.kfv-roth.de/index.php/service/datenschutz"
                          sx={{p: 1, flexShrink: 0}}>
                        Datenschutz
                    </Link>
                    <Link color="inherit" noWrap key="kontakt" variant="body2" href="https://www.kfv-roth.de/index.php/service/kontakt"
                          sx={{p: 1, flexShrink: 0}}>
                        Kontakt
                    </Link>
                </Container>
            </Box>
        </Box>
    );
}

export default Footer;
