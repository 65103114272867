import {LoginRedirectFetch} from "../environment/LoginRedirectFetch";
import {json} from "node:stream/consumers";
import {ok} from "node:assert";

export class BookingService {
    private loginRedirectFetch: LoginRedirectFetch

    constructor() {
        this.loginRedirectFetch = new LoginRedirectFetch(window.location);
    }

    async getBookings(): Promise<string | any> {
        const response = await this.loginRedirectFetch.fetch("/api/bookings/", {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async getBooking(bookingId: string | undefined): Promise<string | any> {
        const response = await this.loginRedirectFetch.fetch(`/api/bookings/view/${bookingId}`, {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async manageBooking(bookingId: string | undefined): Promise<string | any> {
        const response = await this.loginRedirectFetch.fetch(`/api/bookings/manage/${bookingId}`, {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

    async approveCosts(bookingId: any): Promise<string> {
        const response = await this.loginRedirectFetch.fetch(`/api/bookings/workflow/${bookingId}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            credentials: "include"
        })
        return response.json()
    }

    async changeStatus(bookingId: any, status: string): Promise<string> {
        const response = await this.loginRedirectFetch.fetch(`/api/bookings/workflow/${bookingId}`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(status),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            credentials: "include"
        })
        return response.json()
    }


    async getCourse(courseId: string | undefined): Promise<string> {
        const response = await this.loginRedirectFetch.fetch(`/api/courses/view/${courseId}`, {
            method: "GET",
            credentials: "include"
        })
        return response.json();
    }

// make this public
    async getUnits(): Promise<string> {
        const response = await this.loginRedirectFetch.fetch("/api/units/", {
            method: "GET",
            credentials: "include"
        })
        return response.json()
    }

    async findUserData(email: string): Promise<string> {
        // unitid will be part of the user data. User will be authenticated by the cookie!
        const response = await this.loginRedirectFetch.fetch(`/api/attendees/search?email=${email}`, {
            method: "GET",
            credentials: "include"
        })
        return response.json()
    }

    //@todo: Type!!
    async createAttendee(attendee: any): Promise<Response> {
        try {
            return await (await this.loginRedirectFetch.fetch("/api/attendees/add/", {
                method: "PUT",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify(attendee)
            })).json()
        } catch (e: any) {
            throw Error("Fehler im API-Call")
        }
    }

    async createBooking(booking: any): Promise<Response> {
        try {
            return await this.loginRedirectFetch.fetch("/api/bookings/add/", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify(booking),
                credentials: "include"
            })
        } catch (e: any) {
            throw Error("Fehler im API-Call")
        }


    }
}
