import React, {ReactElement, useEffect, useState} from "react";
import "./BookingsOverview.css";
import {
    Container,
    Grid
} from "@mui/material";
import {BookingService} from "../services/BookingService";
import {FmdBadOutlined} from "@mui/icons-material";
import {useParams} from "react-router-dom";

interface BookingDetailsPageProps {
    bookingService: BookingService
}

interface BookingDetailsPageState {
    // @todo Types!
    booking: any;
    loading: boolean
}

export type Booking = {
    id: number,
    status: string
    attendee: Attendee,
    course: Course
}

export type Attendee = {
    id?: number | undefined,
    title?: string,
    firstname?: string,
    middlename?: string,
    lastname?: string,
    addr_street_nr?: string,
    addr_line2?: string,
    addr_postcode?: number | string,
    addr_city?: string,
    email?: string,
    mobile?: string,
    phone?: string,
    date_of_birth?: string,
    unit_id?: string,
    unit_function?: string,
    profession?: string,
    qualifications?: Array<string>
}

export type Course = {
    id?: number,
    course_template_id?: number,
    course_nr?: string,
    registration_opens?: string,
    registration_closes?: string,
    start_date?: string,
    end_date?: string,
    created?: string
}

const BookingDetailsPage = ({bookingService = new BookingService()}: BookingDetailsPageProps): any => {

    const {bookingId} = useParams()
    const initBooking: Booking = {id: 0, status: "", attendee: {}, course: {}};
    const [booking, setBooking] = useState<Booking>(initBooking);

    const gdprWarning = (gdprStatus: string | null) => {
        if (gdprStatus != null) {
            return ("")
        } else {
            return (<FmdBadOutlined color="warning"/>)
        }
    }

    const statusEffect = (status: string) => {
        switch (status) {
            case "CONFIRMED":
                return (
                    <div className="status">
                        <i className="confirmed"></i>
                        <span>Bestätigt</span>
                    </div>);
            case "RESERVED":
                return (
                    <div className="status">
                        <i className="reserved"></i>
                        <span>Reserviert</span>
                    </div>);
            case "REJECTED":
                return (
                    <div className="status">
                        <i className="rejected"></i>
                        <span>Abgelehnt</span>
                    </div>);
        }
    }

    const headerEffect = (booking: any) => {
        if (booking.status == "CONFIRMED" && booking.attendee.gdpr_consent_id != null) {
            return "header-success"
        }else if (booking.status == "REJECTED")  {
            return "header-error"
        }else if (booking.status == "CLOSED")  {
            return "header-closed"
        }
        else {
            return "header-warning"
        }
    }

    useEffect(() => {
        const getBooking = async () => {
            await bookingService.getBooking(bookingId).then((json) => {
                setBooking(json.booking)
            }).catch((e) => {
                console.error(e)
            })
        }
        getBooking().catch(console.error)
    }, [])

    if (booking != undefined) {
        return (
            <div>
                <Container maxWidth={"lg"} style={{paddingTop: "10rem", position: "relative"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={9}>
                            <h1>Buchungsdetails</h1>
                            <h3>Buchungsdetails</h3>

                            <h3>Teilnehmer</h3>

                            <h3>Lehrgang</h3>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default BookingDetailsPage;
