import {
    Button,
    Grid, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Header} from "../common/Header";
import React, {useContext, useEffect, useState} from "react";
import {LoginService} from "./LoginService";


interface ProfileProps {
    loginService: LoginService
}

export const Profile = (props: ProfileProps) => {

    const [userdata, setUser] = useState<any>({firstname: "", roles: [], units: [], groups: [], courses: []})

    useEffect(() => {
        const user = async () => {
            const response = await props.loginService.user()
            setUser(response.user)

        }
        user().catch(console.error)
    }, [])

    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
            {userdata ? (
                <>
                    <Header/>
                    <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                        <Typography variant={"h1"}>Mein Portal</Typography>
                        <Typography variant={"body1"}>{`${userdata.firstname} ${userdata.lastname}`}</Typography>
                        <Typography variant={"body1"}>{`${userdata.email}`}</Typography>
                        <Typography variant={"h2"}>Hilfe</Typography>
                        <Typography variant={"body1"}>
                            Anleitung herunterladen
                        </Typography>
                        <Typography variant={"h2"}>Meine Berechtigungen</Typography>
                        <Typography variant={"body1"}>Über Berechtigungen und Rollen werden die Zugriffe auf Personen und Organisationen geregelt.</Typography>
                        <Typography variant={"h3"}>Rollen</Typography>
                        <Paper elevation={2} sx={{padding: "1.5rem"}}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Beschreibung</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userdata.roles.map((role: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{role.name}</TableCell>
                                                <TableCell>{role.roledesc}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <Typography variant={"h3"}>Gruppen</Typography>
                        <Paper elevation={2} sx={{padding: "1.5rem"}}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Orga-Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userdata.groups.map((group: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{group.name}</TableCell>
                                                <TableCell>{group.org_type.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <Typography variant={"h3"}>Verwaltbare Einheiten</Typography>
                        <Paper elevation={2} sx={{padding: "1.5rem"}}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userdata.units.map((unit: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{unit.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid></>) : (<>
                <Header/>
                <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                </Grid></>)}
        </Grid>
    )
}
