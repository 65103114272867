import React from "react";

interface ImprintPageProps {
}

interface ImprintPageState {
}

export class ImprintPage extends React.Component<ImprintPageProps, ImprintPageState> {
    constructor(props: ImprintPageProps) {
        super(props);
    }
    render() {
        return (
            <div>Imprint</div>
        );
    }
}
