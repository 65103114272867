import {
    Alert, AlertTitle,
    Autocomplete,
    Box,
    Button,
    Collapse,
    FormControl,
    FormGroup,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {LoginService} from "./LoginService";
import {UserContext} from "../App";
import {Header} from "../common/Header";
import {useRoutes, useSearchParams} from "react-router-dom";

interface LoginProps {
    loginService: LoginService
}

export const Login = (props: LoginProps) => {
    const {user, setUser} = useContext(UserContext);
    const [loginError, setLoginError] = useState(false);
    const [searchParams] = useSearchParams()
    const onLoginFormSubmit = async (event: any) => {
        event.preventDefault();
        try {
            let redirectLocation = '/profil'

            const resp = await props.loginService.login(new FormData(event.currentTarget));

            if (resp.status !== 200) {
                throw new Error("Login failed.");
            }
            const loggedInUser = await resp.json();
            setUser(loggedInUser.user)
            if (searchParams.get('state')) {
                redirectLocation = `/${decodeURIComponent(searchParams.get('state') ?? "")}`
            }
            window.location.assign(redirectLocation)
        } catch (e: any) {
            setLoginError(true)
            console.error(e);
        }
    }

    useEffect(() => {
        props.loginService.session().then((response: any) => {
            if (!response) {
                setUser(null)
            } else {
            }
        }).catch((reason: any) => {
            setUser(null)
        })
    }, [])
    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
            <Header/>
            <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                <Typography variant={"h1"}>Login</Typography>
                <Collapse in={loginError}>
                    <Alert severity="error">
                        <AlertTitle>Fehler!</AlertTitle>
                        Sie konnten nicht angemeldet werden. Bitte überprüfen Sie Ihren Benutzername und das Passwort.
                    </Alert>
                </Collapse>
                <form onSubmit={onLoginFormSubmit} style={{margin: '1.5rem 0 0 0'}}>
                    <FormControl>
                        <FormGroup row={true} sx={{margin: "0 0 2rem 0"}}>
                            <TextField id="username"
                                       name="username" label="Benutzername"
                                       onChange={(e) => {
                                       }} required/>
                        </FormGroup>
                        <FormGroup row={true} sx={{margin: "0 0 2rem 0"}}>
                            <TextField id="password"
                                       name="password" label="Kennwort"
                                       type="password"
                                       onChange={(e) => {
                                       }} required/>
                        </FormGroup>
                        <FormGroup row={true}>
                            <Button type="submit" variant="contained">Anmelden</Button>
                        </FormGroup>
                    </FormControl>
                </form>
            </Grid>
        </Grid>
    )
}
