import React, {ReactElement, useContext, useEffect, useState} from "react";
import "../landing/Landing.css";

import {
    Box, Button,
    Collapse,
    Container,
    Grid, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {CourseService} from "../services/CourseService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Header} from "../common/Header";
import {UserContext} from "../App";
import {Check} from "@mui/icons-material";

interface CourseDetailsPageProps {
    courseService: CourseService
}

interface CourseDetailsPageState {
    course: any;
    loading: boolean
}

type CourseTemplate = {
    id?: number,
    courses: Array<Course>,
    name: string,
    alias: string,
    course_nr?: string,
    registration_opens?: string,
    educational_goals: string,
    duration: string,
    targetgroup: string,
    clothes: string,
    bring_in: string,
    educational_content: string
    registration_closes?: string,
    start_date?: string,
    end_date?: string,
    created?: string,
    contact_details: string,
    prerequisites: any
}

type Course = {
    id?: number
}

const BookingDetailsPage = ({courseService = new CourseService()}: CourseDetailsPageProps): any => {

    const {courseAlias} = useParams()
    const initCourse: CourseTemplate = {
        id: 0,
        name: "",
        alias: "",
        courses: [],
        educational_goals: "",
        duration: "",
        targetgroup: "",
        clothes: "",
        bring_in: "",
        educational_content: "",
        contact_details: "",
        prerequisites: []
    };
    const [course, setCourse] = useState<CourseTemplate>(initCourse);

    useEffect(() => {
        const getCourse = async () => {
            await courseService.getCourse(courseAlias).then((json) => {
                setCourse(json.courseTemplate[0])
            }).catch((e) => {
                console.error(e)
            })
        }
        getCourse().catch(console.error)
    }, [])

    const {user} = useContext(UserContext);

    if (course != undefined) {
        return (
            <Grid container maxWidth={'lg'} sx={{margin: 'auto', minHeight: "100vh"}}>
                <Header />
                <Grid maxWidth={'lg'} container className={'landingHeroContainer'}>
                    <Grid item xs={12} md={6} sx={{padding: '0 1rem 0 1rem'}}>
                        <h5 style={{color: '#CA2B2B', fontWeight: 'bold'}}>FEUERWEHREN DES LANDKREISES ROTH</h5>
                        <Typography variant={"h1"}>{course.name}</Typography>
                        <Typography variant={"body1"}>
                            {course.educational_goals}
                        </Typography>
                        <Typography variant={"h3"}>Lehrgangsdauer</Typography>
                        <Typography variant={"body1"}>
                            {course.duration}
                        </Typography>

                        <Typography variant={"h3"}>Zielgruppe</Typography>
                        <Typography variant={"body1"}>
                            {course.targetgroup}
                        </Typography>
                        <Typography variant={"h3"}>Lerninhalte</Typography>
                        <Typography variant={"body1"}>
                            {course.educational_content}
                        </Typography>
                        <Typography variant={"h3"}>Sonstiges</Typography>
                        <Typography variant={"body1"}>
                            {course.clothes}
                        </Typography>
                        <Typography variant={"h3"}>Kontakt</Typography>
                        <Typography variant={"body1"}>
                            {course.contact_details}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={require("../landing/landing.jpg")} className="landinghero"/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem'}}>
                    <Typography variant={"h3"}>Lehrgangsvoraussetzungen</Typography>
                    <Paper elevation={2} sx={{padding: "1.5rem"}}>
                        <List>
                            {course.prerequisites.map((element: any, index: number) => (
                                <ListItem key={index}>
                                    <ListItemIcon><Check/></ListItemIcon>
                                    <ListItemText>{element.prerequisite}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                    <Typography variant={"h3"}>Aktuelle Lehrgänge</Typography>
                    <Paper elevation={2} sx={{padding: "1.5rem"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Kursnummer</TableCell>
                                        <TableCell>Von</TableCell>
                                        <TableCell>Bis</TableCell>
                                        <TableCell>Ort</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {course.courses.map((course: any, index: number) => (
                                        <TableRow
                                            key={index}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {course.course_nr}
                                            </TableCell>
                                            <TableCell>{new Date(course.start_date).toLocaleDateString("de")}</TableCell>
                                            <TableCell> {new Date(course.end_date).toLocaleDateString("de")}</TableCell>
                                            <TableCell>{course.address.name}</TableCell>
                                            <TableCell>{user ?
                                                <Button variant={"contained"} endIcon={<ArrowForwardIosIcon/>}
                                                        component={Link}
                                                        to={{pathname: `/buchung/${course.course_nr}`}}>
                                                    Buchen
                                                </Button> : ""}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default BookingDetailsPage;
