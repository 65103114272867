import React from "react";

interface DataPrivacyPageProps {
}

interface DataPrivacyPageState {
}

export class DataPrivacy extends React.Component<DataPrivacyPageProps, DataPrivacyPageState> {
    constructor(props: DataPrivacyPageProps) {
        super(props);
    }
    render() {
        return (
            <div>DataPrivcy</div>
        );
    }
}
