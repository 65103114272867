import React, {ReactElement, useEffect, useState} from "react";
import "./BookingsOverview.css";
import {
    Box, Button,
    Collapse,
    Container,
    Grid, Icon, IconButton,
    Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {BookingService} from "../services/BookingService";
import {FmdBadOutlined, FmdGoodOutlined} from "@mui/icons-material";
import {Header} from "../common/Header";

interface BookingsOverviewPageProps {
    bookingService: BookingService
}

interface BookingsOverviewPageState {
    // @todo Types!
    bookings: any;
    loading: boolean
}

type Bookings = Array<Booking>

type Booking = {
    id: number,
    status: string
    attendee: Attendee,
    course: Course
}

type Attendee = {
    id?: number | undefined,
    title: string,
    firstname: string,
    middlename: string,
    lastname: string,
    addr_street_nr: string,
    addr_line2?: string,
    addr_postcode: number | string,
    addr_city: string,
    email: string,
    mobile?: string,
    phone?: string,
    date_of_birth: string,
    unit_id: string,
    unit_function?: string,
    profession?: string,
    qualifications: Array<string>,
    gdpr_consents:  Array<any>
}

type Course = {
    id: number,
    course_template_id: number,
    course_nr: string,
    registration_opens: string,
    registration_closes: string,
    start_date: string,
    end_date: string,
    created: string
}

const BookingsOverviewPage = (props: BookingsOverviewPageProps): any => {
    const initBookings: Bookings = [];
    const [bookings, setBookings] = useState<Bookings>(initBookings);

    const gdprWarning = (gdprStatus: string | null) => {
        if (gdprStatus === "YES") {
            return (<FmdGoodOutlined color="success" />)
        } else {
            return (<FmdBadOutlined color="warning"/>)
        }
    }

    const statusEffect = (status: string) => {
        switch (status) {
            case "CONFIRMED":
                return (
                    <div className="status">
                        <i className="confirmed"></i>
                        <span>Bestätigt</span>
                    </div>);
            case "COST_APPROVAL":
                return (
                    <div className="status">
                        <i className="reserved"></i>
                        <span>Warten auf Kostenübernahme</span>
                    </div>);
            case "RESERVED":
                return (
                    <div className="status">
                        <i className="reserved-static"></i>
                        <span>Reserviert</span>
                    </div>);
            case "REJECTED":
                return (
                    <div className="status">
                        <i className="rejected"></i>
                        <span>Abgelehnt</span>
                    </div>);
            case "CLOSED":
                return (
                    <div className="status">
                        <i className="closed"></i>
                        <span>Abgeschlossen</span>
                    </div>);
        }
    }

    useEffect(() => {
        const getBookings = async () => {
            await props.bookingService.getBookings().then((json) => {
                setBookings(json.bookings)
            }).catch((e) => {
                console.error(e)
            })
        }
        getBookings().catch(console.error)
    }, [])

    if (bookings != undefined) {
        return (
            <Grid container maxWidth={'lg'} sx={{margin: 'auto', minHeight: "95vh"}}>
                <Header/>
                <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                    <Typography variant="h1">Buchungsübersicht</Typography>
                    <p>Bei Fragen zu bestehenden Buchungen teilen Sie uns bitte immer die Buchungsnummer oder
                        den Lehrgang, sowie Vor- und Nachname des Teilnehmers mit.</p>
                    <TableContainer component={Paper}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Erstellt</TableCell>
                                <TableCell align="right">Lehrgang</TableCell>
                                <TableCell align="right">Teilnehmer</TableCell>
                                <TableCell align="right">Feuerwehr</TableCell>
                                <TableCell align="right">Optionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings.map((booking: any, index: number) => (
                                <TableRow>
                                    <TableCell>{booking.id}</TableCell>
                                    <TableCell>{statusEffect(booking.status)}</TableCell>
                                    <TableCell>{booking.created_at}</TableCell>
                                    <TableCell>{booking.course?.course_nr ? booking.course.course_nr : ""}</TableCell>
                                    <TableCell sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }}>{gdprWarning(booking.attendee.gdpr_consents[0].grpc_consent_given)} {`${booking.attendee.lastname}, ${booking.attendee.firstname}`}</TableCell>
                                    <TableCell>{booking.attendee.unit.name}</TableCell>
                                    <TableCell>
                                        <Link to={{pathname: `/buchung/workflow/${booking.id}/`}}>Verwalten</Link>
                                        {/*<Link to={{pathname: `/buchung/details/${booking.id}/`}}>Details</Link>*/}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    }
}

export default BookingsOverviewPage;
