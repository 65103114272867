import React from 'react';
import { Link } from 'react-router-dom';
import {Box, Grid, Typography} from "@mui/material";
import {Header} from "./Header";

export const NotFound = () => {
    return (
        <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
            <Header />
            <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem', minHeight: "80vh"}}>
                <Typography variant={"h1"}>404 - Seite nicht gefunden!</Typography>
            </Grid>
        </Grid>
    )
}
