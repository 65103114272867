import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import './BookingPage.css';

import {
    Alert, AlertTitle, Autocomplete,
    Avatar,
    Button,
    Checkbox, Collapse,
    Container, dividerClasses, FormControl,
    FormControlLabel,
    FormGroup, FormLabel,
    Grid, InputAdornment, InputLabel,
    MenuItem, Radio, RadioGroup,
    Select, SelectChangeEvent,
    TextField
} from "@mui/material";
import {BookingService} from "../services/BookingService";
import Box from "@mui/material/Box";
import {Link, useParams} from "react-router-dom";
import {Send} from "@mui/icons-material";
import {Header} from "../common/Header";
import {LoginService} from "../user/LoginService";

interface BookingPageProps {
    bookingService: BookingService,
    loginService: LoginService
}

interface BookingPageState {
    course?: any;
    searchEmail?: any;
    loading: boolean;
}

// refactor. Move this into another class and maybe use something like a global state.
type User = {
    id: number,
    units: Array<Unit>
}

type Course = {
    id?: any;
    course_nr: string;
    course_template: CourseTemplate;
}

type CourseTemplate = {
    id: number,
    name: string,
    prerequisites: any
}

type Unit = {
    id: number,
    name: string
}

type Qualification = {
    short: string,
    long: string
}

type Attendee = {
    id?: number | undefined,
    title: string,
    firstname: string,
    middlename: string,
    lastname: string,
    addr_street_nr: string,
    addr_line2?: string,
    addr_postcode: number | string,
    addr_city: string,
    email: string,
    mobile?: string,
    phone?: string,
    date_of_birth: string,
    unit_id: string,
    unit_function?: string,
    profession?: string
}

const BookingPage = (props: BookingPageProps): any => {

    const {courseId} = useParams()
    const initCourse: Course = {
        id: undefined,
        course_nr: "",
        course_template: {id: 0, name: "", prerequisites: []}
    }

    const initAttendee: Attendee = {
        id: undefined,
        email: "",
        firstname: "",
        lastname: "",
        title: "",
        middlename: "",
        addr_street_nr: "",
        addr_line2: "",
        addr_postcode: "",
        addr_city: "",
        phone: "",
        mobile: "",
        date_of_birth: "",
        unit_id: "",
        unit_function: "",
        profession: ""
    }
    const initSearchEmail: string = ""

    const [course, setCourse] = useState<Course>(initCourse);
    const [searchEmail, setSearchEmail] = useState<string>(initSearchEmail);
    const [attendee, setAttendee] = useState<Attendee>(initAttendee);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [user, setUser] = useState<User>({id: 0, units: []});
    const [autofilled, setAutoFilledCssClass] = useState<boolean>(false);
    const [successMsgOpen, setSuccessMsgOpen] = useState(false);
    const [errorMsgOpen, setErrorMsgOpen] = useState(false);

    interface formDataType {
        [key: string]: FormDataEntryValue
    }

    const qualifications: Array<Qualification> = [
        {short: "MTA-Basis", long: "Modulare Truppmann Ausbildung Basis"},
        {short: "MTA-Abschluss", long: "Modulare Truppmann Ausbildung Abschluss"},
        {short: "TF", long: "Truppführer"},
        {short: "GF", long: "Gruppenführer"},
        {short: "ZF", long: "Zugführer"},
        {short: "SpF", long: "Sprechfunk"},
        {short: "THL", long: "Technische Hilfeleistung"},
        {short: "MA", long: "Maschinist Teil 1 / 2"},
        {short: "AT", long: "Atemschutzgeräteträger"},
        {short: "JW", long: "Jugendwart"}
    ]
    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const responseBody: formDataType = {}
        const formData = new FormData(event.currentTarget as HTMLFormElement)
        formData.forEach((value, property: string) => responseBody[property] = value);

        try {
            setIsSaving(true)
            const saveAttendee = async () => {
                return await props.bookingService.createAttendee(attendee)
            }
            const createBooking = async (attendeeId: number) => {
                return await props.bookingService.createBooking({attendee_id: attendeeId, course_id: course.id})
            }

            const respA: any = await saveAttendee();
            const respB = await createBooking(respA.attendee.id);
            if (!respB.ok) {
                throw Error("Fehler beim Speichern der Buchung")
            }
            setIsSaving(false)
            setSuccessMsgOpen(true)
        } catch (e) {
            setErrorMsgOpen(true);
            console.error(e)
        }
    }

    const onChangeSearchEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchEmail(event.currentTarget.value)
    }

    const onSearchFormSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const searchPerson = async () => {
            const json: any = await props.bookingService.findUserData(searchEmail)
            setLoading(false)
            if (json.attendee) {
                if (json.attendee.qualifications == "") {
                    json.attendee.qualifications = []
                }
                setAttendee(json.attendee);
                setAutoFilledCssClass(true)
                setTimeout(() => {
                    setAutoFilledCssClass(false)
                }, 900)
            } else {
                alert("Benutzer nicht gefunden")
            }
        }
        searchPerson().catch(console.error);

    }
    const inputChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
        const {name, value} = event.target
        setAttendee({...attendee, [name]: value})
        console.log(attendee);
    }

    useEffect(() => {
        //get Login State
        const user = async () => {
            // refactoring!
            const json: any = await props.loginService.user((window.location.pathname).substring(1))
            setUser(json.user)
        }
        user().catch(console.error)
    }, [])

    useEffect(() => {
        const getCourse = async () => {
            const json: any = await props.bookingService.getCourse(courseId)
            setCourse(json.course[0])
            setLoading(false)
        }
        getCourse().catch(console.error);
    }, []);
    if (course != undefined) {
        return (
            !loading ?
                <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
                    <Header/>
                    <Grid item xs={12} md={9} sx={{padding: '0 1rem 0 1rem'}}>
                        <form onSubmit={onSearchFormSubmitHandler}>
                            <h2>Teilnehmersuche</h2>
                            <FormGroup>
                                <p>Sie können eine E-Mail Adresse verwenden, um nach bereits vorhandenen Teilnehmern
                                    zu
                                    suchen. Bitte beachten Sie, dass Sie nur Personen aus Ihrer Organisation sehen
                                    können.</p>
                                <TextField id="serach-email" name="serach-email" type="email" label="E-Mail"
                                           placeholder="Bitte E-Mail eingeben" value={searchEmail}
                                           onChange={onChangeSearchEmailHandler}/>
                                <Button type="submit" variant="contained">Suche</Button>
                            </FormGroup>
                        </form>
                        <form onSubmit={onSubmitHandler}>
                            <h1>Lehrgangsbuchung anlegen {course.course_nr}</h1>
                            <Box sx={{
                                '& .MuiTextField-root': {mt: 1.5, mr: 1.5, width: '45%'}
                            }}>
                                <h2>Teilnehmer</h2>
                                <h3>Persönliche Daten</h3>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Titel</FormLabel>
                                    <RadioGroup
                                        className={autofilled ? 'autofilled' : ''} id="title"
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="title"
                                        value={attendee.title}
                                        onChange={(e) => inputChangeHandler(e)}>
                                        <FormControlLabel value="Herr" control={<Radio/>} label="Herr"/>
                                        <FormControlLabel value="Frau" control={<Radio/>} label="Frau"/>
                                        <FormControlLabel value="Divers" control={<Radio/>} label="Divers"/>
                                    </RadioGroup>
                                </FormControl>

                                <FormGroup row={true}>
                                    <TextField className={autofilled ? 'autofilled' : ''} id="firstname"
                                               name="firstname" label="Vorname" value={attendee.firstname}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                    <TextField className={autofilled ? 'autofilled' : ''} id="lastname"
                                               name="lastname" label="Nachname" value={attendee.lastname}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                </FormGroup>
                                <FormGroup row={true}>
                                    <TextField id="date_of_birth" name="date_of_birth" label="Geburtsdatum (DD/MM/YYYY)"
                                               InputProps={{
                                                   startAdornment: <InputAdornment position="start"></InputAdornment>,
                                               }}
                                               className={autofilled ? 'autofilled' : ''}
                                               type="date"
                                               value={attendee.date_of_birth}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                    <TextField id="profession" name="profession" label="Beruf"
                                               className={autofilled ? 'autofilled' : ''}
                                               value={attendee.profession} onChange={(e) => inputChangeHandler(e)}
                                               required/>
                                </FormGroup>
                                <h3>Adresse</h3>
                                <FormGroup row={true}>
                                    <TextField id="addr_street_nr" name="addr_street_nr" label="Strasse, Nr."
                                               className={autofilled ? 'autofilled' : ''}
                                               value={attendee.addr_street_nr}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                    <TextField id="addr_line2" name="addr_line2" label="Adresszeile 2"
                                               className={autofilled ? 'autofilled' : ''}
                                               value={attendee.addr_line2} onChange={(e) => inputChangeHandler(e)}/>
                                    <TextField id="addr_postcode" name="addr_postcode" label="PLZ"
                                               className={autofilled ? 'autofilled' : ''}
                                               value={attendee.addr_postcode}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                    <TextField id="addr_city" name="addr_city" label="Wohnort"
                                               className={autofilled ? 'autofilled' : ''}
                                               value={attendee.addr_city}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                </FormGroup>
                                <h3>Kontaktdaten</h3>
                                <FormGroup row={true}>
                                    <TextField className={autofilled ? 'autofilled' : ''} id="phone"
                                               name="phone" label="Telefon"
                                               value={attendee.phone}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                    <TextField className={autofilled ? 'autofilled' : ''} id="mobile"
                                               name="mobile" label="Telefon / Mobile"
                                               value={attendee.mobile}
                                               onChange={(e) => inputChangeHandler(e)}/>
                                    <TextField className={autofilled ? 'autofilled' : ''} id="email" name="email"
                                               type="email" label="E-Mail" value={attendee.email}
                                               onChange={(e) => inputChangeHandler(e)} required/>
                                </FormGroup>
                                <h3>Organisation</h3>
                                <FormGroup row={true}>
                                    <FormControl required sx={{mt: 1.5, mr: 1.5, width: '45%'}}>
                                        <InputLabel id="feuerwehr-label">Feuerwehr</InputLabel>
                                        <Select className={autofilled ? 'autofilled' : ''} id="unit_id"
                                                name="unit_id"
                                                labelId="feuerwehr-label" defaultValue="" value={attendee.unit_id}
                                                onChange={(e) => inputChangeHandler(e)}
                                        >
                                            {user.units.map((unit: Unit, index: number) => (
                                                <MenuItem key={index} value={unit.id}>{unit.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField id="unit_function" name="unit_function" label="Funktion"
                                               value={attendee.unit_function}
                                               onChange={(e) => inputChangeHandler(e)}/>
                                </FormGroup>
                                <h2>Bemerkung</h2>
                                <FormGroup row={true}>
                                    <TextField id="bermerkung" name="bemerkung" label="Bemerkung" minRows="5"
                                               multiline/>
                                </FormGroup>
                                <h2>Lehrgangsvoraussetzungen</h2>
                                <div>
                                    {course.course_template.prerequisites.map((element: any, index: number) => (
                                        <li key={index}>{element.prerequisite}</li>
                                    ))}
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="lehrgangsvoraussetzungen"
                                                      id="lehrgangsvoraussetzungen" required/>
                                        }
                                        label="Ich bestätige die Einhaltung der Voraussetzungen."/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="datenschutz" id="datenschutz" required/>
                                        }
                                        label="Ich bestätige, dass ich die Datenschutzbestimmungen gelesen und verstanden habe."
                                    />
                                </div>
                                <Button
                                    type="submit" variant="contained"
                                    endIcon={isSaving ? "..." : ""}>
                                    Buchung absenden
                                </Button>
                            </Box>
                        </form>
                        <Collapse in={successMsgOpen}>
                            <Alert severity="success">
                                <AlertTitle>Erfolgreich!</AlertTitle>
                                Die Buchung wurde erfolgreich gespeichert! Eine Kopie der Anmeldung wurde an den
                                Teilnehmer
                                versendet. Bitte verfolgen Sie die Statusänderungen.
                            </Alert>
                        </Collapse>
                        <Collapse in={errorMsgOpen}>
                            <Alert severity="error">
                                <AlertTitle>Fehler!</AlertTitle>
                                Ihre Buchung konnte nicht gespeichert werden.
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>
                :
                <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
                    <Header/>
                    <div>
                        .. laden
                    </div>
                </Grid>
        )
    }
}
export default BookingPage;
