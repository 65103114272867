import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";

interface CourseCalendarProps {
    courses?: any
}

interface CourseCalendarState {
    courses?: any
}

export class CourseCalendar extends React.Component<CourseCalendarProps, CourseCalendarState> {
    constructor(props: CourseCalendarProps) {
        super(props);
        this.state = {
            courses: this.props.courses
        }
    }
    renderAddress = (address: any) => {
        if (address == null) {
            return <b>Noch kein Ort bekannt.</b>
        } else {
            return <p>Ort: {address.name}</p>
        }
    }
    private month = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "November", "Dezember"]

    render() {
        return (
            <div>
                <Typography variant={"h1"}>Kalender</Typography>
                {this.state.courses.map((element: any, index: number) => (
                    <div key={index}>
                        <Typography variant={"h2"}>{this.month[element[0] - 1]}</Typography>
                        {element[1].map((course: any, index: number) => (
                            <div key={index}>
                                <h4>{`${course.course_template.name} (${course.course_nr})`}</h4>
                                <span>{course.participants}% belegt.</span>
                                <LinearProgress value={course.participants} variant="determinate"/>
                                <div>{`Lehrgang von: ${new Date(course.start_date).toLocaleDateString("de")} bis
                                                                     ${new Date(course.end_date).toLocaleDateString("de")}`}
                                </div>
                                <div>
                                    {course.course_template.educational_goals}
                                </div>
                                <div>
                                    {this.renderAddress(course.address)}
                                </div>
                                <Link to={{pathname: `/kurs/details/${course.course_template.alias}`}}> Mehr Erfahren</Link>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    };
}
