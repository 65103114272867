import {LoginRedirectFetch} from "../environment/LoginRedirectFetch";


export class LoginService {
    private loginRedirectFetch: LoginRedirectFetch

    constructor() {
        this.loginRedirectFetch = new LoginRedirectFetch(window.document.location)
    }

    private apiHost(): string {
        if (window.location.hostname === "localhost") {
            return "http://localhost:8080"
        } else {
            return "https://lehrgang.ff-lkr-roth.de"
        }
    }

    // We use a separate fetch implementation not to have a redirect loop on Login.
    private async fetch(input: Request | string, init?: RequestInit): Promise<Response> {
        return await fetch(this.apiHost() + input, init);
    }

    async login(formData: FormData): Promise<Response> {
        return await this.loginRedirectFetch.fetch("/api/users/login", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: `username=${formData.get("username")}&password=${formData.get("password")}`,
            credentials: "include"
        })
    }

    async logout(): Promise<any> {
        return await this.loginRedirectFetch.fetch("/api/users/logout", {
            method: "GET",
            credentials: "include"
        })
    }

    async session(): Promise<any> {
        const response = await this.fetch("/api/user", {
            method: "GET",
            mode: "cors",
            credentials: "include"
        })
        return response.json();
    }

    async user(state?: any): Promise<any> {
        // der state kann eine URL oder undefined sein.
        const response = await this.loginRedirectFetch.fetch("/api/user/", {
            method: "GET",
            mode: "cors",
            credentials: "include"
        }, state)
        return response.json();
    }
}
